<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="Excel文件">
				   <el-upload
						class="upload-demo d-inline-block mx-1"
						ref="upload"
						action="#"
						:on-change="(file, fileList) => {importExcel(file)}"
						:on-success="importSuccess"
						:on-error="importError"
						:before-upload="beforeImportUpload"
						:on-remove="handleRemove"
						:file-list="fileList"
						:limit="1"
						:multiple="false"
						:show-file-list="true"
						accept=".xls, .xlsx"
						:auto-upload="false"
						>
						<el-button slot="trigger" icon="el-icon-upload2" size="medium" type="warning">上传文件</el-button>
						<div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
				   </el-upload>
				</el-form-item>
				<el-form-item label="">
				   <el-button type="primary" size="medium" @click="handleSubmit">导 入</el-button>
				</el-form-item>
			</el-form>
			
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'user',
			autoRequest: false,
			form:{
				content:'',
			},
			rules:{
               content: [
                 { required: true, message: '请输入手机号码', trigger: 'blur' }
               ],
			},
			fileList:[],
			file:{},
		}
	},
	mounted() {

	},
	methods:{
		handleSubmit(){
			this.$refs.form.validate(valid => {
			  if (valid) {
				  //获取用户选择的文件
				  if(Object.keys(this.file).length == 0){
					  this.$message.error('请上传文件')
					  return false
				  }
				  let formdata = new FormData()
				  formdata.append('file', this.file)
				  // formdata.append('type_id', this.form.type_id)
				  this.axios.post('/manage/user/importData',formdata).then(res=>{
					if(res.status){
						this.$message.success('导入成功')
					}else{
						this.$message.error('导入失败')
					}
					this.$refs.upload.clearFiles()
				  })
			  }
			})
		},
		importExcel(e){
			console.log(e)
			const file = e.raw
			this.file = file
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
	},
}
</script>

<style>
</style>